<template>
  <span>
    <v-container>
      <v-layout
        row
        :justify-center="$vuetify.breakpoint.smAndDown"
        :justify-left="$vuetify.breakpoint.smAndUp">
        <img
          :class="this.$vuetify.breakpoint.smAndUp ? 'company_logo':'company_logo_mobile'"
          class="company_logo"
          :src="logo_img"
        />
      </v-layout>
    </v-container>  
  </span>
</template>
<script>
export default {
  data: () => ({

  }),
  props: {
    logo_img: String,
    company_name: String
  }
}
</script>
<style lang="scss">
.company_logo {
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
}
.company_logo_mobile {
  z-index: 1;
  max-width: 80px;
  max-height: 80px;
}
.company_name {
  cursor: default;
  letter-spacing: 2px;
  margin: 20px;
  z-index: 1;
  font-family: 'Aleo', sans-serif;
  font-weight: bold;
  color: white;
  font-size: 25px;
  max-width: 155px
}
.company_name_mobile {
  cursor: default;
  letter-spacing: 2px;
  margin: 20px;
  z-index: 1;
  font-family: 'Aleo', sans-serif;
  font-weight: bold;
  color: white;
  font-size: 15px;
  max-width: 150px
}
</style>